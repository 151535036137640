import { Container, Grid } from '@mui/material';
import React, { useEffect, useState } from "react";
import { isAdmin, isManager } from '../../services/auth';

import Autocomplete from '@mui/material/Autocomplete';
import FormCard from "../../components/forms/formCard";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import PageHeaderButton from '../../components/pageHeaderButton';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import SaleTable from "./saleTable";
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { api } from '../../services/api';
import dayjs from 'dayjs';
import { sumPreReceivedValues, sumReceivedValues } from "../../common/utils";

const Sale = props => {
    const [saleList, setSaleList] = useState([]);
    const [allSalesList, setAllSalesList] = useState([]);
    const [srcSaleList, setSrcSaleList] = useState([]);
    const [field, setField] = useState("");
    const [valuesList, setValuesList] = useState([]);

    const mapFields = {
        "Kav": "code",
        "Cliente": "razaoSocial",
        "Data": "dataVenda"
    }

    useEffect(() => {
        let mounted = true;
        api.getSales()
            .then(resp => {
                if (mounted && resp) {
                    sumAllSalesValues(resp);
                    setSaleList(resp);
                    setSrcSaleList(resp);
                    setAllSalesList(resp);
                }
            })
        return () => mounted = false;
    }, [])

    const handleChangePriority = (event) => {
        if (event.target.value === "open") {
            let filteredSales = allSalesList.filter(a => a.totalEmAberto > 0)
            setSaleList(filteredSales);
            setSrcSaleList(filteredSales);
        }
        if (event.target.value === "closed") {
            let filteredSales = allSalesList.filter(a => a.totalEmAberto <= 0)
            setSaleList(filteredSales);
            setSrcSaleList(filteredSales);
        }
        if (event.target.value === "all") {
            setSaleList(allSalesList);
            setSrcSaleList(allSalesList);
        }
    };

    const sumAllSalesValues = (sales) => {
        let totalValue = 0;
        let totalReceived = 0;
        sales.forEach(item => {
            let totalReceivedItem = sumReceivedValues(item.recebimentos);
            totalValue += item.totalDolar;
            totalReceived += totalReceivedItem;

            item.totalQuitado = totalReceivedItem;
            item.totalEmAberto = item.totalDolar - totalReceivedItem;
            item.totalPreRecebimento = sumPreReceivedValues(item.preRecebimento) - sumReceivedValues(item.preRecebimento);
        });
        return [totalValue - totalReceived, totalValue, totalReceived];
    }

    const handleFieldSelect = (value) => {
        if (value) {
            let valuesFiltered = [];
            if (mapFields[value] === "razaoSocial") {
                valuesFiltered = srcSaleList.map(elem => elem.importadora.razaoSocial);
            } else {
                if (mapFields[value] === "dataVenda") {
                    valuesFiltered = srcSaleList.map(elem => dayjs(elem[mapFields[value]]).format('DD/MM/YYYY').toString());
                } else {
                    valuesFiltered = srcSaleList.map(elem => elem[mapFields[value]]);
                }
            }
            setValuesList(valuesFiltered.filter((item, index) => valuesFiltered.indexOf(item) === index));
            setField(mapFields[value]);
        }
    };

    useEffect(() => {
        if (valuesList.length > 0) {
            console.log("Values List: ", valuesList);
        }
    }, [valuesList]);

    const handleValueSelect = (value) => {
        let listFiltered = srcSaleList;
        if (value && value.length > 0) {
            if (field === "razaoSocial") {
                if (value !== "") {
                    listFiltered = srcSaleList.filter(a => value.includes(a.importadora.razaoSocial));
                } else {
                    listFiltered = srcSaleList;
                }
            } else {
                if (field === "dataVenda") {
                    listFiltered = srcSaleList.filter(a => value.includes(dayjs(a[field]).format('DD/MM/YYYY').toString()));
                } else {
                    listFiltered = srcSaleList.filter(a => value.includes(a[field]));
                }
            }
            setSaleList(listFiltered);
        } else {
            setSaleList(srcSaleList);
        }
    };

    return (
        <Container maxWidth="xg" sx={{ mt: 4, mb: 4 }}>
            <Grid item xs={12}>
                <PageHeaderButton
                    title="Vendas"
                    path="/sales"
                    canCreate={isAdmin() || isManager()}></PageHeaderButton>
            </Grid>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper variant="outlined" sx={{ my: { xs: 3, md: 0 }, p: { xs: 2, md: 3 }, borderRadius: '10px' }}>
                        <Grid item xs={12} sm={12}>
                            <FormCard title="Filtros">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12}>
                                        <FormControl>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontSize: "12px" }}>Considerar vendas:</FormLabel>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                onChange={handleChangePriority}
                                                defaultValue="open"
                                            >
                                                <FormControlLabel value="open" control={<Radio style={{ height: "5px" }} size='small' />}
                                                    label={<Typography style={{ fontSize: "12px" }}>Não Quitadas</Typography>} />
                                                <FormControlLabel value="closed" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Quitadas</Typography>} />
                                                <FormControlLabel value="all" control={<Radio size='small' />} label={<Typography style={{ fontSize: "12px" }}>Todas</Typography>} />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={2}>
                                        <Autocomplete
                                            size="small"
                                            disablePortal
                                            id="field"
                                            getOptionLabel={(option) => option}
                                            options={Object.keys(mapFields)}
                                            onChange={(event, value) => handleFieldSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                                label="Campo a ser filtrado" />}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={10}>
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            disablePortal
                                            id="value"
                                            getOptionLabel={(option) => option}
                                            options={valuesList}
                                            onChange={(event, value) => handleValueSelect(value)}
                                            sx={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                label="Conteúdo"
                                                InputProps={{ ...params.InputProps, style: { fontSize: "12px" } }}
                                                InputLabelProps={{ ...params.InputLabelProps, style: { fontSize: "12px" } }}
                                            />}
                                        />
                                    </Grid>
                                </Grid>
                            </FormCard>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Grid item xs={12}>
                        <SaleTable rows={saleList} />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );

};

export default Sale;
